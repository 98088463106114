// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abc-magnete-tsx": () => import("./../../../src/pages/abc-magnete.tsx" /* webpackChunkName: "component---src-pages-abc-magnete-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-definition-tsx": () => import("./../../../src/pages/definition.tsx" /* webpackChunkName: "component---src-pages-definition-tsx" */),
  "component---src-pages-g-mbooks-tsx": () => import("./../../../src/pages/g-mbooks.tsx" /* webpackChunkName: "component---src-pages-g-mbooks-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wiederrufsbelehrung-tsx": () => import("./../../../src/pages/wiederrufsbelehrung.tsx" /* webpackChunkName: "component---src-pages-wiederrufsbelehrung-tsx" */)
}

